<template>
  <div class="plan-detail">
    <div class="top-content">
      <el-button class="edit-btn" type="primary">修改</el-button>
    </div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="ruleForm"
      :size="formSize"
    >
      <el-form-item label="方案名称：" prop="name">
        <span>综合提成</span>
      </el-form-item>
      <el-form-item label="计算方式：" prop="name">
        <span>阶梯式结算</span>
      </el-form-item>

      <el-form-item label="选择职级：" prop="name">
        <div class="rank">
          <el-button size="small" plain>初级理疗师</el-button>
          <el-button size="small" plain>高级理疗师</el-button>
        </div>
      </el-form-item>

      <el-form-item label="商品提成规则：" prop="name">
        <div class="rule-site">
          <div class="titles">
            <div>业绩区间</div>
            <div>适应产品</div>
            <div>提成类型</div>
            <div>提成点</div>
          </div>
          <div class="rule-form">
            <div class="range">
              <span>0</span>
              <span>到</span>
              <span>1000</span>
            </div>
            <div>
              <el-button size="small" plain>全部服务</el-button>
            </div>
            <div>比例提成</div>
            <div>
              <span>2</span>
              <span>%</span>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="服务提成规则：" prop="name">
        <div class="rule-site">
          <div class="titles">
            <div>业绩区间</div>
            <div>适应产品</div>
            <div>提成类型</div>
            <div>提成点</div>
          </div>
          <div class="rule-form">
            <div class="range">
              <span>0</span>
              <span>到</span>
              <span>1000</span>
            </div>
            <div>
              <el-button size="small" plain>全部服务</el-button>
            </div>
            <div>比例提成</div>
            <div>
              <span>1</span>
              <span>%</span>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="卡项提成规则：" prop="name">
        <div class="rule-site">
          <div class="titles">
            <div>业绩区间</div>
            <div>适应产品</div>
            <div>提成类型</div>
            <div>提成点</div>
          </div>
          <div class="rule-form">
            <div class="range">
              <span>0</span>
              <span>到</span>
              <span>1000</span>
            </div>
            <div>
              <el-button size="small" plain>全部服务</el-button>
            </div>
            <div>比例提成</div>
            <div>
              <span>1</span>
              <span>%</span>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="适用门店：" prop="name">
        <span>全部门店</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.plan-detail {
  .top-content {
    display: flex;
    justify-content: flex-end;
    .edit-btn {
      width: 88px;
      height: 40px;
    }
  }
  .ruleForm {
    &:deep(.el-input) {
      max-width: 340px;
    }
    .btn-wrap {
      margin-top: 10px;
      .save-btn {
        width: 88px;
        height: 40px;
      }
    }
    .rule-site {
      min-width: 720px;
      max-width: 970px;

      .titles {
        padding: 0 10px;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 14px;
        display: flex;
        div {
          flex: 1;
          text-align: center;
        }
      }

      .rule-form {
        display: flex;
        margin-top: 10px;
        .range {
          span:nth-child(2n + 1) {
            width: 44px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            display: inline-block;
            border: 1px solid rgba(166, 166, 166, 1);
            border-radius: 2px;
            font-size: 12px;
            margin: 0 6px;
          }
        }
        div {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          &:deep(.el-input) {
            max-width: 60px;
          }
          &:deep(.el-select) {
            .el-input {
              max-width: 120px;
            }
          }
        }
      }
    }

    .rank {
      max-width: 340px;

      .titles {
        display: flex;
        justify-content: space-between;
        height: 40px;
        background-color: rgba(239, 239, 239, 1);
        font-size: 14px;
        padding: 0 10px;
      }
      .rank-list {
        padding: 4px 10px;
        .rankk-item {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          span:nth-child(2) {
            color: rgba(42, 130, 228, 1);
          }
        }
      }
    }
  }
}
</style>








'

export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '提成设置',
    path: ''
  },
  {
    name: '方案列表',
    path: ''
  },
  {
    name: '方案详情',
    path: ''
  }
]

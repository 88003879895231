<template>
  <div class="detail">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="detail-content">
      <plan-detail></plan-detail>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import PlanDetail from './cpns/plan-detail/plan-detail.vue'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    PlanDetail
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.detail {
  .detail-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




